// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCeCTBpBY0QINmSHyWVFzSwYy8qmNry2WQ',
  authDomain: 'kedrbots-5fee0.firebaseapp.com',
  projectId: 'kedrbots-5fee0',
  storageBucket: 'kedrbots-5fee0.appspot.com',
  //databaseURL: "http://127.0.0.1:9000/?ns=kedrbots-5fee0-default-rtdb", // DEV
  databaseURL:
    'https://kedrbots-5fee0-default-rtdb.europe-west1.firebasedatabase.app', // PROD
  messagingSenderId: '801189597219',
  appId: '1:801189597219:web:8a241cf4a2050cca04e196',
  measurementId: 'G-RW8YXW5BS0',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

export const rdb = getDatabase(firebaseApp)

export const auth = getAuth(firebaseApp)
export const analytics = getAnalytics(firebaseApp)

export default rdb
