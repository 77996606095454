// sep
// import sepHomeworkData from './tasks_to_homeworks_sep.json'
// import sepTaskMetadata from './task_to_level_flat_sep.json'
// oct
import octHomeworkData from './tasks_to_homeworks_oct.json'
import octTaskMetadata from './task_to_level_flat_oct.json'
// nov
import novHomeworkData from './tasks_to_homeworks_nov.json'
import novTaskMetadata from './task_to_level_flat_nov.json'
// dec
import decHomeworkData from './tasks_to_homeworks_dec.json'
import decTaskMetadata from './task_to_level_flat_dec.json'
// jan
import janHomeworkData from './tasks_to_homeworks_jan.json'
import janTaskMetadata from './task_to_level_flat_jan.json'
// feb
import febHomeworkData from './tasks_to_homeworks_feb.json'
import febTaskMetadata from './task_to_level_flat_feb.json'
// mar
import marHomeworkData from './tasks_to_homeworks_mar.json'
import marTaskMetadata from './task_to_level_flat_mar.json'
// apr
import aprHomeworkData from './tasks_to_homeworks_apr.json'
import aprTaskMetadata from './task_to_level_flat_apr.json'
// may
import mayHomeworkData from './tasks_to_homeworks_may.json'
import mayTaskMetadata from './task_to_level_flat_may.json'
// jun
import junHomeworkData from './tasks_to_homeworks_jun.json'
import junTaskMetadata from './task_to_level_flat_jun.json'

export const homeworkData = {
  // sept: sepHomeworkData,
  oct: octHomeworkData,
  nov: novHomeworkData,
  dec: decHomeworkData,
  jan: janHomeworkData,
  feb: febHomeworkData,
  mar: marHomeworkData,
  apr: aprHomeworkData,
  may: mayHomeworkData,
  jun: junHomeworkData,
}

export const taskMetadata = {
  // sept: sepTaskMetadata,
  oct: octTaskMetadata,
  nov: novTaskMetadata,
  dec: decTaskMetadata,
  jan: janTaskMetadata,
  feb: febTaskMetadata,
  mar: marTaskMetadata,
  apr: aprTaskMetadata,
  may: mayTaskMetadata,
  jun: junTaskMetadata,
}

export function getHomeworkData(questions, month) {
  const monthKey = monthMapping[month]
  let homeworks = {}
  if (monthKey && questions[monthKey]?.length) {
    questions[monthKey].forEach((q) => {
      if (homeworks[q.homework]) {
        homeworks[q.homework].push(q)
      } else {
        homeworks[q.homework] = [q]
      }
    })
    return Object.keys(homeworks).map((h) => {
      return {
        homeworkNumber: h,
        questionIdList: homeworks[h].map((q) => q.order),
      }
    })
  } else {
    return []
  }
}

export function getTaskMetadata(questions, month) {
  const monthKey = monthMapping[month]
  let result = {}
  if (monthKey && questions[monthKey]?.length) {
    questions[monthKey].forEach((q) => {
      result[`${q.order}`] = q.level
    })
    return result
  } else {
    return {}
  }
}

export const currentMonth = 'dec' // TODO: auto calculate
export const monthMapping = {
  sep: 'сент',
  oct: 'окт',
  nov: 'ноя',
  dec: 'дек',
  jan: 'янв',
  feb: 'фев',
  mar: 'март',
  apr: 'апр',
  may: 'май',
  jun: 'июнь',
}
