import React from 'react'
import './userEstimation.scss'

function getEstimation(user, filters) {
  const MAX_NUMBER_OF_QUESTIONS = 100
  if (!user[`${filters.subject}_ct_answers`]) {
    return 0
  }
  let answers = Object.values(user[`${filters.subject}_ct_answers`]).flatMap(
    (monthAnswers) => Object.values(monthAnswers)
  )
  answers.sort((a, b) => {
    return parseInt(b.questionId) - parseInt(a.questionId)
  })
  if (answers.length > MAX_NUMBER_OF_QUESTIONS) {
    answers = answers.slice(0, MAX_NUMBER_OF_QUESTIONS)
  }
  const countOfAnswers = answers.length
  const rightAnswers = answers.filter((a) => a.isRight).length
  return Math.round((rightAnswers / countOfAnswers) * 100)
}

export const UserEstimation = ({ user, filters }) => {
  const estimation = getEstimation(user, filters)
  if (!estimation) return null
  return (
    <div className="user-estimation">
      <div className="user-estimation__number">{estimation}</div>
      <div className="user-estimation__description">Баллов на ЦТ</div>
    </div>
  )
}
