import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Select } from 'antd'
import {
  setFilters,
  changeStatsMonth,
  changeSubject,
} from '../../features/userStats/userStatsSlice'
import './graphSettingsUser.scss'
import { UserEstimation } from '../'

const { Option } = Select

export const GraphSettingsUser = ({ filters, months, user }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const userOptions = ['rus', 'math', 'bio', 'hist', 'eng', 'bel'].filter(
    (subject) => {
      return user[`${subject}_ct_answers`]
    }
  )

  return (
    <>
      <div>
        <Select
          className="graph-settings__select"
          key="subject"
          value={filters.subject}
          onChange={(subject) => setSearchParams({ subject })}
        >
          {userOptions.includes('rus') && (
            <Option key="rus" value="rus">
              {'Русский язык'}
            </Option>
          )}
          {userOptions.includes('math') && (
            <Option key="math" value="math">
              {'Математика'}
            </Option>
          )}
          {userOptions.includes('bio') && (
            <Option key="bio" value="bio">
              {'Биология'}
            </Option>
          )}
          {userOptions.includes('hist') && (
            <Option key="hist" value="hist">
              {'История Беларуси'}
            </Option>
          )}
          {userOptions.includes('eng') && (
            <Option key="eng" value="eng">
              {'Английский'}
            </Option>
          )}
          {userOptions.includes('bel') && (
            <Option key="bel" value="bel">
              {'Белорусский язык'}
            </Option>
          )}
        </Select>
      </div>
      <UserEstimation user={user} filters={filters} />
      <div className="graph-settings">
        <Select
          className="graph-settings__select"
          key="month"
          value={filters.month}
          onChange={(month) => dispatch(changeStatsMonth(month))}
        >
          {months.map((m) => (
            <Option key={m.value} value={m.value}>
              {m.label}
            </Option>
          ))}
        </Select>
        <Select
          className="graph-settings__select"
          key="type"
          value={filters.type}
          onChange={(type) => dispatch(setFilters({ ...filters, type }))}
        >
          <Option value="answers">Правильных ответов</Option>
          <Option value="progress">Прогресс</Option>
        </Select>
      </div>
    </>
  )
}
