import React from 'react'
import { GraphRow } from '../graphRow/graphRow'

function prepareData(users, filters, homeworks) {
  const studentAnswersKey = `${filters.subject}_ct_answers`
  const filteredUsers = users.filter(
    (u) => u[studentAnswersKey] && u[studentAnswersKey][filters.month]
  )
  const homework = homeworks.find((h) => h.homeworkNumber === filters.homework)
  const questionsNumber = homework ? homework.questionIdList.length : -1
  const questionsOld = filteredUsers
    .flatMap((student) => {
      return Object.values(student[studentAnswersKey][filters.month])
    })
    .filter((elem) => {
      const question = Number.parseInt(elem.questionId)
      return !homework || homework.questionIdList.some((q) => q === question)
    })
    .map((el) => el.questionId)
  const total = new Set(questionsOld).size
  let preparedData = filteredUsers.map((student) => {
    const result = {
      name: student.name,
      id: student.id,
    }
    const answers = Object.values(
      student[studentAnswersKey][filters.month]
    ).filter((answer) => {
      const question = Number.parseInt(answer.questionId)
      return !homework || homework.questionIdList.some((q) => q === question)
    })
    result.total = total
    result.right = answers.filter((a) => a.isRight).length
    result.persent = result.right
      ? Math.min(Math.floor((result.right * 100) / result.total), 100)
      : 0
    result.description = `${result.persent}%`
    result.isRelevant =
      answers.length === questionsNumber || questionsNumber <= 0
    return result
  })
  preparedData.sort((a, b) => b.persent - a.persent)
  return preparedData
}

export const StudentsGraph = ({ data, filters, homeworks }) => {
  const preparedData = prepareData(data, filters, homeworks)
  // const relevant = preparedData.filter((e) => e.isRelevant)
  // const notRelevantYet = preparedData.filter((e) => !e.isRelevant)
  return (
    <>
      <div style={{ display: 'table', padding: '0 24px' }}>
        {preparedData.map((data, index) => (
          <GraphRow key={data.id} index={index + 1} data={data} />
        ))}
      </div>
      {/* {notRelevantYet.length ? (
        <div className="not-relevant-students">
          <span>Не сдали ({notRelevantYet.length}):</span>
          <br />
          {notRelevantYet.map((data) => (
            <span key={data.id} className="not-relevant-students__item">
              {data.name?.trim()}
              {', '}
            </span>
          ))}
        </div>
      ) : null} */}
    </>
  )
}
