import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit'
import { getHomeworkData, getTaskMetadata, currentMonth } from '../../data'

const initialState = {
  id: null,
  status: 'idle',
  currentMonth,
  filters: {
    month: currentMonth,
    type: 'answers',
    subject: 'rus',
  },
  rawData: undefined,
  months: [
    { value: 'sep', label: 'Сентябрь' },
    { value: 'oct', label: 'Октябрь' },
    { value: 'nov', label: 'Ноябрь' },
    { value: 'dec', label: 'Декабрь' },
    { value: 'jan', label: 'Январь' },
    { value: 'feb', label: 'Февраль' },
    { value: 'mar', label: 'Март' },
    { value: 'apr', label: 'Апрель' },
    { value: 'may', label: 'Май' },
    { value: 'jun', label: 'Июнь' },
  ],
  questions: null,
  homeworks: [],
  taskMetadata: {},
}

export const fetchUserData = createAsyncThunk(
  'userStats/fetchData',
  async (userId, { getState }) => {
    const state = getState()
    if (!userId) {
      return null
    }
    const instance = state.charts?.fireInstance
    const result = await instance.getStatsByUserId(
      userId,
      state.userStats.filters.month,
      state.userStats.currentMonth
    )
    // The value we return becomes the `fulfilled` action payload
    return result
  }
)

export const fetchQuestionsData = createAsyncThunk(
  'userStats/fetchQuestions',
  async (_, { getState }) => {
    const state = getState()
    const instance = state.charts?.fireInstance
    const result = await instance.getQuestions(state.userStats.filters)
    return result
  }
)

export const changeStatsMonth = createAsyncThunk(
  'userStats/changeMonth',
  (month, { dispatch, getState }) => {
    dispatch(updateMonthFilter(month))
    dispatch(fetchUserData(getState().userStats?.rawData?.id))
  }
)

export const changeSubject = createAsyncThunk(
  'userStats/changeSubject',
  (subject, { dispatch }) => {
    dispatch(updateSubject(subject))
    dispatch(fetchQuestionsData())
  }
)

const updateMonthFilter = createAction('userStats/updateMonthFilter')
const updateSubject = createAction('userStats/updateSubject')

export const userStatsSlice = createSlice({
  name: 'userStats',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserId: (state, action) => {
      state.id = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    updateMonthFilter: (state, action) => {
      const newMonth = action.payload
      state.filters.month = newMonth
      state.homeworks = getHomeworkData(state.questions, newMonth)
      state.taskMetadata = getTaskMetadata(state.questions, newMonth)
    },
    updateSubject: (state, action) => {
      const subject = action.payload
      state.filters.subject = subject
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = 'idle'
        state.rawData = action.payload
      })
      .addCase(fetchQuestionsData.fulfilled, (state, action) => {
        state.homeworks = getHomeworkData(action.payload, state.currentMonth)
        state.taskMetadata = getTaskMetadata(action.payload, state.currentMonth)
        state.questions = action.payload
      })
  },
})

export const { setCode, setFilters, setData } = userStatsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.charts.value)`
export const selectUserStatsLoadingStatus = (state) => state.userStats.status
export const selectUserData = (state) => state.userStats.rawData
export const selectFilters = (state) => state.userStats.filters
export const selectMonths = (state) => state.userStats.months
export const selectHomeworks = (state) => state.userStats.homeworks
export const selectTaskMetadata = (state) => state.userStats.taskMetadata
export const selectQuestions = (state) => state.userStats.questions

export default userStatsSlice.reducer
