import React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { Layout, Spin, Space } from 'antd'
import {
  selectUserStatsLoadingStatus,
  fetchUserData,
  selectUserData,
  selectMonths,
  selectFilters,
  selectHomeworks,
  selectTaskMetadata,
  fetchQuestionsData,
  changeSubject,
} from '../features/userStats/userStatsSlice'
import { selectFireInstance } from '../features/charts/chartsSlice'
import { GraphSettingsUser, GraphUser, UserEstimation } from '../components'
import './userPage.scss'

const { Content } = Layout

function UsersPage() {
  const { search } = useLocation()
  let { id } = useParams()
  const dispatch = useDispatch()
  const user = useSelector(selectUserData)
  const fireInstance = useSelector(selectFireInstance)
  const loadingStatus = useSelector(selectUserStatsLoadingStatus)
  const months = useSelector(selectMonths)
  const filters = useSelector(selectFilters)
  const homeworks = useSelector(selectHomeworks)
  const taskMetadata = useSelector(selectTaskMetadata)
  const query = new URLSearchParams(search)
  const selectedSubject = query.get('subject')
  useEffect(() => {
    async function initialize() {
      await fireInstance.signIn()
      await dispatch(fetchUserData(id))
      await dispatch(fetchQuestionsData())
    }
    initialize()
  }, [dispatch, fireInstance, id])
  const shouldShowLoading = typeof user === 'undefined'
  const graphLoading = loadingStatus === 'loading'
  if (selectedSubject && selectedSubject !== filters.subject) {
    dispatch(changeSubject(selectedSubject))
  }
  return (
    <div className="Page">
      <Content>
        {!shouldShowLoading ? (
          user ? (
            <>
              <h1 className="user-page__title">{`${user.name || ''} ${
                user.last_name || ''
              }`}</h1>
              <GraphSettingsUser
                user={user}
                filters={filters}
                months={months}
              />
              {graphLoading ? (
                <Space size="large">
                  <Spin
                    className="user-page__loading"
                    tip="Загрузка данных..."
                    size="large"
                  />
                </Space>
              ) : (
                <GraphUser
                  filters={filters}
                  data={user}
                  homeworks={homeworks}
                  taskMetadata={taskMetadata}
                  months={months}
                />
              )}
            </>
          ) : (
            <h1>Что-то пошло не так, попробуйте перезагрузить страницу 😿</h1>
          )
        ) : (
          <Space size="large">
            <Spin
              className="user-page__loading"
              tip="Загрузка данных..."
              size="large"
            />
          </Space>
        )}
      </Content>
    </div>
  )
}

export default UsersPage
