// import {
//   collection,
//   getDocs,
//   getDoc,
//   doc,
//   query,
//   where,
//   orderBy,
//   // limit,
//   onSnapshot,
// } from 'firebase/firestore'
import { ref, child, get } from 'firebase/database'
import { signInAnonymously } from 'firebase/auth'
import { auth, rdb } from './firebase'

// let unsubscribe, onUpdateCallback

// async function getLastUserAnswers(userId, count, currentMonth) {
//   const months = [
//     'dec',
//     'nov',
//     'oct',
//     // 'sep',
//     // 'aug',
//     // 'jul',
//     // 'jun',
//     // 'may',
//     // 'apr',
//     // 'mar',
//     'feb',
//     'jan',
//   ]
//   let maxCyclesCount = 4 // fail safe
//   const result = []
//   let currentMonthIndex = months.indexOf(currentMonth)
//   try {
//     while (result.length < count) {
//       const month = months[currentMonthIndex % months.length]
//       currentMonthIndex += 1
//       const leftCount = count - result.length
//       const answersCollection = collection(db, `rus_${month}_answers`)
//       const qAnswers = query(
//         answersCollection,
//         where('__name__', '<=', `${userId}\uf8ff`),
//         where('__name__', '>=', `${userId}`)
//       )
//       const answersSnapshot = await getDocs(qAnswers)
//       let answers = answersSnapshot.docs.map((doc) => {
//         const [_, questionId] = doc.id.split('_')
//         return {
//           id: doc.id,
//           questionId: Number.parseInt(questionId),
//           ...doc.data(),
//         }
//       })
//       if (maxCyclesCount-- < 0) {
//         return result
//       }
//       answers.sort((a, b) => b.questionId - a.questionId)
//       result.push(...answers.slice(0, leftCount))
//     }
//   } catch (e) {
//     console.error(`get last user answers error ${e}`)
//   }
//   return result
// }

const initialize = () => {
  return {
    signIn: async () => {
      return await signInAnonymously(auth)
    },
    getUsersStats: async () => {
      try {
        const result = {}
        const dbRef = ref(rdb)
        const usersSnapshot = await get(child(dbRef, `users`))
        if (usersSnapshot && usersSnapshot.exists()) {
          usersSnapshot.forEach((userDoc) => {
            result[userDoc.key] = {
              ...userDoc.val(),
              id: userDoc.key,
              answers: [],
            }
          })
        }
        return Object.values(result)
      } catch (e) {
        console.error('error ', e)
        return null
      }
    },
    getQuestions: async (filters) => {
      try {
        const result = {}
        const dbRef = ref(rdb)
        const questionsSnapshot = await get(
          child(dbRef, `${filters.subject}_ct_questions`)
        )
        if (questionsSnapshot && questionsSnapshot.exists()) {
          questionsSnapshot.forEach((qDoc) => {
            const val = qDoc.val()
            if (val && val.type === 'quiz') {
              if (result[val.month]) {
                result[val.month].push(val)
              } else {
                result[val.month] = [val]
              }
            }
          })
        }
        return result
      } catch (e) {
        console.error(`getQuestions error ${e}`)
        return null
      }
    },
    getStatsByUserId: async (userId, month, currentMonth) => {
      try {
        if (!month || !userId) {
          return {}
        }
        const dbRef = ref(rdb)
        const userSnapshot = await get(child(dbRef, `users/${userId}`))
        if (userSnapshot?.exists()) {
          console.log('get user ', userId, userSnapshot.val())
          return {
            id: userSnapshot.key,
            ...userSnapshot.val(),
          }
        } else {
          return null
        }
      } catch (e) {
        console.error('error ', e)
        return null
      }
    },
    // subscribe: (month) => {
    //   if (!month) {
    //     return
    //   }
    //   const now = new Date()
    //   const dateOffset = 1000 * 60 // 60 sec overlap time.. just in case
    //   now.setTime(now.getTime() - dateOffset)
    //   // now.setMinutes(now.get)
    //   const answersRef = collection(db, `rus_${month}_answers`)
    //   const answersQuery = query(answersRef, where('createdAt', '>=', now))
    //   unsubscribe = onSnapshot(answersQuery, (snapshot) => {
    //     snapshot.docChanges().forEach((change) => {
    //       if (onUpdateCallback) {
    //         onUpdateCallback(change.doc.id, change.doc.data(), change.type)
    //       }
    //     })
    //   })
    // },
    // onUpdateCall: (callback) => {
    //   onUpdateCallback = callback
    // },
    // unsubscribe: () => {
    //   unsubscribe && unsubscribe()
    // },
  }
}

export default initialize
